import {
  GET_LIST,
  GET_ONE,
  GET_MANY,
  GET_MANY_REFERENCE,
  CREATE,
  UPDATE,
  DELETE,
  fetchUtils,
} from 'react-admin';
import { stringify } from 'query-string';
import S3Uploader from './S3Uploader';

const API_URL = 'https://api-v2.instalivros.com/v1/admin';
// const API_URL = 'https://instalivros-api-dev.herokuapp.com/v1/admin';
// const API_URL = 'http://localhost:3000/v1/admin';

/**
* @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
* @param {String} resource Name of the resource to fetch, e.g. 'posts'
* @param {Object} params The Data Provider request params, depending on the type
* @returns {Object} { url, options } The HTTP request parameters
*/
const convertDataProviderRequestToHTTP = (type, resource, params) => {
  switch (type) {
  case GET_LIST: {
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const query = params.filter;
      return {
        url: `${API_URL}/${resource}?${stringify(query)}`,
        options: {
          headers: new Headers({
            'X-Current-Page': page - 1,
            'X-Per-Page': perPage,
          }),
        },
      };
  }
  case GET_ONE:
      return { url: `${API_URL}/${resource}/${params.id}` };
  case GET_MANY: {
      const query = {
          filter: JSON.stringify({ id: params.ids }),
      };
      return { url: `${API_URL}/${resource}?${stringify(query)}` };
  }
  case GET_MANY_REFERENCE: {
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const query = { ...params.filter, [params.target]: params.id };
      return {
        url: `${API_URL}/${resource}?${stringify(query)}`,
        options: {
          headers: new Headers({
            'X-Current-Page': page - 1,
            'X-Per-Page': perPage,
          }),
        },
      };
  }
  case UPDATE: {
      return {
          url: `${API_URL}/${resource}/${params.id}`,
          options: { method: 'PUT', body: JSON.stringify(params.data) },
      };
    }
  case CREATE: {
      return {
          url: `${API_URL}/${resource}`,
          options: { method: 'POST', body: JSON.stringify(params.data) },
      };
  }
  case DELETE:
      return {
          url: `${API_URL}/${resource}/${params.id}`,
          options: { method: 'DELETE' },
      };
  default:
      throw new Error(`Unsupported fetch action type ${type}`);
  }
};

/**
* @param {Object} response HTTP response from fetch()
* @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
* @param {String} resource Name of the resource to fetch, e.g. 'posts'
* @param {Object} params The Data Provider request params, depending on the type
* @returns {Object} Data Provider response
*/
const convertHTTPResponseToDataProvider = (response, type, resource, params) => {
  const { headers, json } = response;
  switch (type) {
  case GET_LIST:
      return {
          data: json.map(x => x),
          total: parseInt(headers.get('Items-Count')),
      };
  case GET_MANY_REFERENCE:
    return {
      data: json.map(x => x),
      total: parseInt(headers.get('Items-Count')),
    }
  case CREATE:
      return { data: { ...params.data, id: json.id } };
    case DELETE:
      return { data: { id: params.id }}
  default:
      return { data: json };
  }
};

/**
* @param {string} type Request type, e.g GET_LIST
* @param {string} resource Resource name, e.g. "posts"
* @param {Object} payload Request parameters. Depends on the request type
* @returns {Promise} the Promise for response
*/
export default (type, resource, params) => {
  const { fetchJson } = fetchUtils;
  const { url, options } = convertDataProviderRequestToHTTP(type, resource, params);

  var httpOptions = options;

  if (!httpOptions) {
    httpOptions = {};
  }
  
  if (!httpOptions.headers) {
    httpOptions.headers = new Headers({ Accept: 'application/json' });
  }

  const token = localStorage.getItem('token');
  httpOptions.headers.set('Authorization', `Bearer ${token}`);

  var promises = []

  if (resource == 'books' && (type == UPDATE || type == CREATE)) {
    if (params.data.picture_file) {
      promises.push(S3Uploader('picture_url', params.data.picture_file, 'image/jpeg'))
    }

    if (params.data.audio_file) {
      promises.push(S3Uploader('audio_url', params.data.audio_file, 'audio/*'))
    }
  }

  return Promise.all(promises)
    .then(uploads => {
      if (uploads && uploads.length > 0) {
        uploads.forEach((upload) => {
          params.data[upload.param] = upload.key
        })

        httpOptions.body = JSON.stringify(params.data)
      }

      return fetchJson(url, httpOptions)
    })
    .then(response => convertHTTPResponseToDataProvider(response, type, resource, params))
    .catch(response => {
      if (response.status == 422) {
        throw { status: 422, message: response.body.errors[0].message };
      } else {
        throw { status: response.status, message: 'Ocorreu um erro inesperado' }
      }
    });
};