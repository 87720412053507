import { DELETE } from 'react-admin';

export const SUBSCRIPTION_CANCEL = 'SUBSCRIPTION_CANCEL';
export const subscriptionCancel = (id, data, basePath) => ({
    type: SUBSCRIPTION_CANCEL,
    payload: { id, data: { ...data } },
    meta: {
        resource: 'subscriptions',
        fetch: DELETE,
        onSuccess: {
            notification: {
                body: 'resources.subscriptions.notification.cancelled_success',
                level: 'info',
            },
            redirectTo: `/subscriptions/${id}`,
            basePath,
        },
        onFailure: {
            notification: {
                body: 'resources.subscriptions.notification.cancelled_failure',
                level: 'warning',
            },
        },
    },
});