import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK } from 'react-admin';

const API_URL = 'https://api-v2.instalivros.com/v1/admin';
// const API_URL = 'https://instalivros-api-dev.herokuapp.com/v1/admin';
// const API_URL = 'http://localhost:3000/v1/admin';

export default (type, params) => {
    if (type === AUTH_LOGIN) {
      const { username, password } = params;
      const request = new Request(`${API_URL}/session`, {
          method: 'POST',
          body: JSON.stringify({ email: username, password: password }),
          headers: new Headers({ 'Content-Type': 'application/json' }),
      })
      return fetch(request)
          .then(response => {
              if (response.status < 200 || response.status >= 300) {
                  throw new Error(response.statusText);
              }
              return response.json();
          })
          .then((json) => {
            localStorage.setItem('token', json.access_token);
          });
    } else if (type === AUTH_LOGOUT) {
      localStorage.removeItem('token');
      return Promise.resolve();
    } else if (type === AUTH_ERROR) {
      const status  = params.status;
      if (status === 401 || status === 403) {
        localStorage.removeItem('token');
        return Promise.reject();
      }
      return Promise.resolve();
    } else if (type === AUTH_CHECK) {
        return localStorage.getItem('token') ? Promise.resolve() : Promise.reject({ redirectTo: '/login' });
    }
    return Promise.reject('Unknown method');
};