import React from 'react';
import { Filter, List, Datagrid, TextField, Create, Show, SimpleShowLayout, Edit, SimpleForm, DisabledInput, TextInput } from 'react-admin';

export const AdminUserFilter = props => (
  <Filter {...props}>
    <TextInput label="Buscar" source="query" alwaysOn />
  </Filter>
);

export const AdminUserList = props => (
  <List {...props} title="Administradores" filters={<AdminUserFilter />} bulkActionButtons={false}>
      <Datagrid rowClick="show">
          <TextField source="id" label="ID" sortable={false} />
          <TextField source="name" label="Nome" sortable={false} />
          <TextField source="email" label="E-mail" sortable={false} />
      </Datagrid>
  </List>
);

export const AdminUserShow = props => (
  <Show {...props}>
      <SimpleShowLayout>
        <TextField source="id" label="ID" />
        <TextField source="name" label="Nome" sortable={false} />
        <TextField source="email" label="E-mail" sortable={false} />
      </SimpleShowLayout>
  </Show>
);

export const AdminUserCreate = props => (
  <Create {...props}>
      <SimpleForm>
          <TextInput source="name" label="Nome" />
          <TextInput source="email" label="E-mail" />
          <TextInput source="password" label="Senha" type="password" />
      </SimpleForm>
  </Create>
);

export const AdminUserEdit = props => (
  <Edit {...props}>
      <SimpleForm>
          <DisabledInput source="id" label="ID" />
          <TextInput source="name" label="Nome" />
          <TextInput source="email" label="E-mail" />
          <TextInput source="password" label="Senha" type="password" />
      </SimpleForm>
  </Edit>
);