import React from 'react';
import { Filter, List, Datagrid, TextField, Create, Show, SimpleShowLayout, Edit, SimpleForm, DisabledInput, TextInput } from 'react-admin';

export const CategoryFilter = props => (
  <Filter {...props}>
    <TextInput label="Buscar" source="query" alwaysOn />
  </Filter>
);

export const CategoryList = props => (
  <List {...props} title="Categorias" filters={<CategoryFilter />} bulkActionButtons={false}>
      <Datagrid rowClick="show">
          <TextField source="id" label="ID" sortable={false} />
          <TextField source="name" label="Nome" sortable={false} />
      </Datagrid>
  </List>
);

export const CategoryShow = props => (
  <Show {...props}>
      <SimpleShowLayout>
        <TextField source="id" label="ID" />
        <TextField source="name" label="Nome" />
      </SimpleShowLayout>
  </Show>
);

export const CategoryCreate = props => (
  <Create {...props}>
      <SimpleForm>
          <TextInput source="name" label="Nome" />
      </SimpleForm>
  </Create>
);

export const CategoryEdit = props => (
  <Edit {...props}>
      <SimpleForm>
          <DisabledInput source="id" label="ID" />
          <TextInput source="name" label="Nome" />
      </SimpleForm>
  </Edit>
);