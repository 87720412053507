import React, { Component } from 'react';
import { Admin, Resource, ListGuesser, EditGuesser } from 'react-admin';
import portugueseMessages from 'ra-language-portuguese';
import { createMuiTheme } from '@material-ui/core/styles';
import AuthProvider from './AuthProvider';
import DataProvider from './DataProvider';
import BookIcon from '@material-ui/icons/Book';
import CategoryIcon from '@material-ui/icons/Dns';
import UserIcon from '@material-ui/icons/People';
import PlanIcon from '@material-ui/icons/MoneyOff';
import SubscriptionIcon from '@material-ui/icons/Payment';
import AdminIcon from '@material-ui/icons/VerifiedUser'
import { CategoryList, CategoryCreate, CategoryShow, CategoryEdit } from './Categories';
import { BookList, BookShow, BookCreate, BookEdit } from './Books';
import { UserList, UserShow, UserEdit } from './Users';
import { PlanList, PlanShow, PlanEdit } from './Plans';
import { SubscriptionList, SubscriptionShow, SubscriptionCreate } from './Subscriptions';
import { AdminUserList, AdminUserShow, AdminUserCreate, AdminUserEdit } from './AdminUsers';
import domainMessages from './i18n';

const messages = {
    pt: { ...portugueseMessages, ...domainMessages },
};
const i18nProvider = locale => messages[locale];

const theme = createMuiTheme({
  palette: {
    primary: { main: '#3f4d60' },
    secondary: { main: '#3f4d60' },
  }
});

const App = () => (
  <Admin authProvider={AuthProvider} dataProvider={DataProvider} title="Instalivros" locale="pt" i18nProvider={i18nProvider} theme={theme}>
    <Resource name="categories" list={CategoryList} show={CategoryShow} create={CategoryCreate} edit={CategoryEdit} options={{ label: 'Categorias' }} icon={CategoryIcon} />
    <Resource name="books" list={BookList} show={BookShow} create={BookCreate} edit={BookEdit} options={{ label: 'Livros' }} icon={BookIcon} />
    <Resource name="users" list={UserList} show={UserShow} edit={UserEdit} options={{ label: 'Usuários' }} icon={UserIcon} />
    <Resource name="plans" list={PlanList} show={PlanShow} edit={PlanEdit} options={{ label: 'Planos' }} icon={PlanIcon} />
    <Resource name="subscriptions" list={SubscriptionList} show={SubscriptionShow} create={SubscriptionCreate} options={{ label: 'Assinaturas' }} icon={SubscriptionIcon} />
    <Resource name="admin_users" list={AdminUserList} show={AdminUserShow} create={AdminUserCreate} edit={AdminUserEdit} options={{ label: 'Administradores' }} icon={AdminIcon} />
  </Admin>
);

export default App;