import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { subscriptionCancel } from './Actions';
import Button from '@material-ui/core/Button';

class CancelButton extends Component {
  handleClick = () => {
    const { subscriptionCancel, record } = this.props;
    subscriptionCancel(record.id, record);
  }

  render() {
    return <Button onClick={this.handleClick}>Cancelar</Button>;
  }
}

CancelButton.propTypes = {
  subscriptionCancel: PropTypes.func.isRequired,
  record: PropTypes.object,
};

export default connect(null, {
  subscriptionCancel
})(CancelButton);