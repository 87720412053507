import React from 'react';
import { Filter, List, Datagrid, TextField, BooleanField, NumberField, Show, SimpleShowLayout,
        Edit, SimpleForm, DisabledInput, TextInput, NumberInput, BooleanInput, Labeled } from 'react-admin';

export const PlanFilter = props => (
  <Filter {...props}>
    <TextInput label="Buscar" source="query" alwaysOn />
  </Filter>
);

export const PlanList = props => (
  <List {...props} title="Planos" filters={<PlanFilter />} bulkActionButtons={false}>
      <Datagrid rowClick="show">
          <TextField source="id" label="ID" sortable={false} />
          <TextField source="name" label="Nome" sortable={false} />
          <TextField source="duration" label="Duração (meses)" sortable={false} />
          <NumberField source="price" label="Preço" sortable={false} />
          <BooleanField source="trial" label="Trial" sortable={false} />
      </Datagrid>
  </List>
);

export const PlanShow = props => (
  <Show {...props}>
      <SimpleShowLayout>
        <TextField source="id" label="ID" sortable={false} />
        <TextField source="name" label="Nome" sortable={false} />
        <TextField source="duration" label="Duração (meses)" sortable={false} />
        <NumberField source="price" label="Preço" sortable={false} />
        <BooleanField source="trial" label="Trial" sortable={false} />
        <TextField source="iugu_plan_id" label="ID do Plano no Iugu" sortable={false} />
      </SimpleShowLayout>
  </Show>
);

export const PlanEdit = props => (
  <Edit {...props}>
      <SimpleForm>
        <DisabledInput source="id" label="ID" />
        <TextInput source="name" label="Nome" />
        <TextInput source="duration" label="Duração (meses)" sortable={false} />
        <NumberInput source="price" label="Preço" sortable={false} />
        <Labeled label="Trial">
          <BooleanInput source="trial" label="" sortable={false} />
        </Labeled>
        <TextInput source="iugu_plan_id" label="ID do Plano no Iugu" sortable={false} />
      </SimpleForm>
  </Edit>
);