import React from 'react';
import { Filter, List, Datagrid, TextField, DateField, SelectField, Show, SimpleShowLayout,
         CardActions, SimpleForm, Create, NumberInput, ReferenceInput, SelectInput, AutocompleteInput } from 'react-admin';
import CancelButton from './CancelButton';

const cardActionStyle = {
  zIndex: 2,
  display: 'inline-block',
  float: 'right',
};

export const SubscriptionFilter = props => (
  <Filter {...props}>
    <ReferenceInput source="plan_id" reference="plans" label="Plano" alwaysOn>
      <SelectInput source="name" />
    </ReferenceInput>
  </Filter>
);

export const SubscriptionList = props => (
  <List {...props} title="Assinaturas" filters={<SubscriptionFilter />} bulkActionButtons={false}>
    <Datagrid rowClick="show">
      <TextField source="id" label="ID" sortable={false} />
      <DateField source="created_at" label="Data" sortable={false} />
      <TextField source="user.name" label="Usuário" sortable={false} />
      <TextField source="plan.name" label="Plano" sortable={false} />
      <SelectField source="gateway" label="Gateway" sortable={false} choices={[
        { id: 'iugu', name: 'Iugu'},
        { id: 'apple', name: 'Apple'},
        { id: 'instalivros', name: 'Instalivros' },
      ]} />
      <SelectField source="status" label="Status" sortable={false} choices={[
        { id: 'pending', name: 'Pendente' },
        { id: 'in_trial', name: 'Trial' },
        { id: 'active', name: 'Ativa' },
        { id: 'expired', name: 'Expirada' },
        { id: 'suspended', name: 'Suspensa' },
        { id: 'cancelled', name: 'Cancelada' },
        ]} />
      <DateField source="expires_at" label="Expiração" sortable={false} />
    </Datagrid>
  </List>
);

const SubscriptionShowActions = ({ basePath, data, resource }) => (
  <CardActions style={cardActionStyle}>
      <CancelButton record={data} />
  </CardActions>
);

export const SubscriptionShow = props => (
  <Show {...props} actions={<SubscriptionShowActions />}>
    <SimpleShowLayout>
      <TextField source="id" label="ID" sortable={false} />
      <DateField source="created_at" label="Data" sortable={false} />
      <SelectField source="gateway" label="Gateway" sortable={false} choices={[
        { id: 'iugu', name: 'Iugu'},
        { id: 'apple', name: 'Apple'},
        { id: 'instalivros', name: 'Instalivros' },
      ]} />
      <SelectField source="status" label="Status" sortable={false} choices={[
        { id: 'pending', name: 'Pendente' },
        { id: 'in_trial', name: 'Trial' },
        { id: 'active', name: 'Ativa' },
        { id: 'expired', name: 'Expirada' },
        { id: 'suspended', name: 'Suspensa' },
        { id: 'cancelled', name: 'Cancelada' },
        ]} />
      <DateField source="expires_at" label="Expiração" sortable={false} />
    </SimpleShowLayout>
  </Show>
);

export const SubscriptionCreate = props => (
  <Create {...props} undoable={false}>
      <SimpleForm>
        <NumberInput source="user_id" label="Código do Usuário" />
        <ReferenceInput source="plan_id" reference="plans" label="Plano">
          <SelectInput source="name" />
        </ReferenceInput>
      </SimpleForm>
  </Create>
);