import React from 'react';
import { Filter, List, Datagrid, TextField, Show, TabbedShowLayout, Tab, Edit, SimpleForm,
         BooleanField, DateField, ReferenceManyField, SelectField, DisabledInput, TextInput } from 'react-admin';

export const UserFilter = props => (
  <Filter {...props}>
    <TextInput label="Buscar" source="query" alwaysOn />
  </Filter>
);

export const UserList = props => (
  <List {...props} title="Usuários" filters={<UserFilter />} bulkActionButtons={false}>
    <Datagrid rowClick="show">
      <TextField source="id" label="ID" sortable={false} />
      <DateField source="created_at" label="Cadastro" sortable={false} />
      <TextField source="name" label="Nome" sortable={false} />
      <TextField source="email" label="E-mail" sortable={false} />
      <BooleanField source="premium" label="Premium" sortable={false} />
    </Datagrid>
  </List>
);

export const UserShow = props => (
  <Show {...props}>
    <TabbedShowLayout>
      <Tab label="INFORMAÇÕES">
        <TextField source="id" label="ID" sortable={false} />
        <DateField source="created_at" label="Cadastro" sortable={false} />
        <TextField source="name" label="Nome" sortable={false} />
        <TextField source="email" label="E-mail" sortable={false} />
        <BooleanField source="premium" label="Premium" sortable={false} />
      </Tab>
      <Tab label="ASSINATURAS" fullWidth>
        <ReferenceManyField reference="subscriptions" target="user_id" label="">
          <Datagrid>
            <TextField source="id" label="ID" sortable={false} />
            <DateField source="created_at" label="Data" sortable={false} />
            <SelectField source="gateway" label="Gateway" sortable={false} choices={[
              { id: 'iugu', name: 'Iugu' },
              { id: 'apple', name: 'Apple' },
              { id: 'instalivros', name: 'Instalivros' },
            ]} />
            <SelectField source="status" label="Status" sortable={false} choices={[
              { id: 'pending', name: 'Pendente' },
              { id: 'in_trial', name: 'Trial' },
              { id: 'active', name: 'Ativa' },
              { id: 'expired', name: 'Expirada' },
              { id: 'suspended', name: 'Suspensa' },
              { id: 'cancelled', name: 'Cancelada' },
              ]} />
            <DateField source="expires_at" label="Expiração" sortable={false} />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
      <Tab label="ESTATÍSTICAS">
        <TextField source="in_progress_books_count" label="Total de Livros em Andamento" sortable={false} />
        <TextField source="read_books_count" label="Total de Livros Lidos" sortable={false} />
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export const UserEdit = props => (
  <Edit {...props}>
      <SimpleForm>
          <DisabledInput source="id" label="ID" />
          <TextInput source="name" label="Nome" />
          <TextInput source="email" label="E-mail" type="email" />
          <TextInput source="password" label="Senha" type="password" />
      </SimpleForm>
  </Edit>
);
