const API_URL = 'https://api-v2.instalivros.com/v1/admin';
// const API_URL = 'https://instalivros-api-dev.herokuapp.com/v1/admin';
// const API_URL = 'http://localhost:3000/v1/admin';

export default (param, file, content_type) => {
  const token = localStorage.getItem('token');
  const request = new Request(`${API_URL}/me/upload_params`, {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }),
    body: JSON.stringify({ content_type: content_type }),
  });

  return fetch(request)
      .then(response => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((json) => {
        let data = new FormData();
        for (let [key, value] of Object.entries(json[0].fields)) {
          data.append(key, value);
        }
        console.log(file.rawFile)
        data.append('file', file.rawFile);
        const s3Request = new Request(json[0].url, {
          method: 'POST',
          body: data
        })

        return fetch(s3Request);
      })
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }

        return { param: param, key: response.headers.get('Location').split('/').pop() }
      });
};