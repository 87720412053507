import React from 'react';
import { List, Datagrid, Filter, Show, TabbedShowLayout, Tab, TextField, DateField, Labeled,
         RichTextField, ArrayField, ChipField, SingleFieldList, SelectField, Create, ImageField, BooleanField, UrlField,
         Edit, TabbedForm, FormTab, SimpleForm, DisabledInput, TextInput, LongTextInput, BooleanInput, ImageInput,
         NumberInput, ReferenceInput, SelectInput, ReferenceArrayInput, AutocompleteArrayInput, FileInput, FileField } from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import S3Uploader from './S3Uploader';

export const BookFilter = props => (
  <Filter {...props}>
    <TextInput label="Buscar" source="query" alwaysOn />
    <ReferenceInput source="category_id" reference="categories" label="Categoria" alwaysOn>
      <SelectInput source="name" />
    </ReferenceInput>
    <SelectInput source="book_type" label="Tipo de Livro" alwaysOn choices={[ {id: 'text', name: 'Livro sem áudio'}, {id: 'audio', name: 'Livro com áudio'} ]} />
  </Filter>
);

export const BookList = props => (
  <List {...props} title="Livros" filters={<BookFilter />} bulkActionButtons={false}>
    <Datagrid rowClick="show">
      <TextField source="id" label="ID" sortable={false} />
      <DateField source="created_at" label="Inclusão" sortable={false} />
      <TextField source="title" label="Título" sortable={false} />
      <TextField source="author" label="Autor" sortable={false} />
      <TextField source="average_rating" label="Avaliação" sortable={false} />
    </Datagrid>
  </List>
);

export const BookShow = props => (
  <Show {...props}>
    <TabbedShowLayout>
      <Tab label="INFORMAÇÕES">
        <TextField source="id" label="ID" />
        <TextField source="title" label="Título" />
        <TextField source="author" label="Autor" />
        <Labeled label="Capa">
          <ImageField source="picture_url" />
        </Labeled>
        <TextField source="description" label="Resumo" />
        <SelectField source="book_type" label="Tipo de Livro" choices={[ {id: 'text', name: 'Livro sem áudio'}, {id: 'audio', name: 'Livro com áudio'} ]} />
        <BooleanField source="free" label="O conteúdo do livro será gratuito" />
        <TextField source="audio_duration" label="Duração do Aúdio (min)" />
        <TextField source="text_duration" label="Duração da Leitura (min)" />
        <ArrayField source="categories" label="Categorias">
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ArrayField>
      </Tab>
      <Tab label="CONTEÚDO">
        <Labeled label="Áudio do Livro">
          <UrlField source="audio_url" />
        </Labeled>
        <RichTextField source="content" label="Texto do Livro" />
      </Tab>
      <Tab label="ESTATÍSTICAS">
        <TextField source="average_rating" label="Avaliação Média" />
        <TextField source="ratings_count" label="Número de Avaliações" />
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export const BookCreate = props => (
  <Create {...props} undoable={false}>
    <TabbedForm>
      <FormTab label="INFORMAÇÕES">
        <TextInput source="title" label="Título" />
        <TextInput source="author" label="Autor" />
        <ImageInput source="picture_file" label="Capa" accept="image/*" >
          <ImageField source="src" title="title" />
        </ImageInput>
        <LongTextInput source="description" label="Resumo" />
        <SelectInput source="book_type" label="Tipo de Livro" choices={[ {id: 'text', name: 'Livro sem áudio'}, {id: 'audio', name: 'Livro com áudio'} ]} />
        <Labeled label="Preço">
          <BooleanInput source="free" label="O conteúdo do livro será gratuito" />
        </Labeled>
        <NumberInput source="audio_duration" label="Duração do Aúdio (min)" />
        <NumberInput source="text_duration" label="Duração da Leitura (min)" />
        <ReferenceArrayInput source="category_ids" reference="categories" label="Categorias" defaultValue={[]}>
          <AutocompleteArrayInput translateChoice={false} />
        </ReferenceArrayInput>
      </FormTab>
      <FormTab label="CONTEÚDO">
        <FileInput source="audio_file" label="Áudio do Livro" accept="audio/*" >
          <FileField source="src" title="title" />
        </FileInput>
        <RichTextInput source="content" label="Texto do Livro" />
      </FormTab>
    </TabbedForm>
  </Create>
);

export const BookEdit = props => (
  <Edit {...props} undoable={false}>
    <TabbedForm>
      <FormTab label="INFORMAÇÕES">
        <DisabledInput source="id" label="ID" />
        <TextInput source="title" label="Título" />
        <TextInput source="author" label="Autor" />
        <ImageInput source="picture_file" label="Capa" accept="image/*" >
          <ImageField source="src" title="title" />
        </ImageInput>
        <LongTextInput source="description" label="Resumo" />
        <SelectInput source="book_type" label="Tipo de Livro" choices={[ {id: 'text', name: 'Livro sem áudio'}, {id: 'audio', name: 'Livro com áudio'} ]} />
        <Labeled label="Preço">
          <BooleanInput source="free" label="O conteúdo do livro será gratuito" />
        </Labeled>
        <NumberInput source="audio_duration" label="Duração do Aúdio (min)" />
        <NumberInput source="text_duration" label="Duração da Leitura (min)" />
        <ReferenceArrayInput source="category_ids" reference="categories" label="Categorias">
          <AutocompleteArrayInput translateChoice={false} />
        </ReferenceArrayInput>
      </FormTab>
      <FormTab label="CONTEÚDO">
        <FileInput source="audio_file" label="Áudio do Livro" accept="audio/*" >
          <FileField source="src" title="title" />
        </FileInput>
        <RichTextInput source="content" label="Texto do Livro" />
      </FormTab>
    </TabbedForm>
  </Edit>
);